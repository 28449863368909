import { result } from '../data/data';

export const getCatalogData = () => {
  return result;
};

// export const getTypeData = () => {
//   const obj = {
//     electricForklift: electricForklift,
//     petrolForklift: petrolForklift,
//   };
//   return obj;
// };
